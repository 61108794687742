.noProposalView {
  display: grid;
  place-items: center;
  flex: 1;
}

.noProposalText {
  margin-bottom: 12px;
}

.newIdea {
  font-family: "cairo";
  font-weight: 600;
  color: white;
  padding: 6px 18px 6px 19px;
  border-radius: 25px;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.15);
  background-color: #645ef3;
}
