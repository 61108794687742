.incorrectNetwork {
  height: 100vh;
  width: 100vw;
  background-color: white;
  padding-top: 1px;
  display: grid;
  place-items: center;
  display: grid;
}

.heading {
  margin-bottom: 80px;
}

.titleBlockContainer {
  display: flex;
  justify-content: center;
}

.titleBlock {
  height: 4px;
  border-radius: 1px;
  background-color: #645ef3;
  width: 4.4em;
  margin-bottom: 80px;
  padding-right: 10px;
  margin-right: 108px;
}

.title {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 6px;
}

.centerText {
  text-align: center;
}

.image {
  align-self: center;
  display: flex;
  justify-content: center;
}
