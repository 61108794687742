.container {
  cursor: pointer;
  user-select: none;
  transition: box-shadow 200ms, transform 200ms;
  border-color: transparent;
  border-width: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  white-space: nowrap; /* Single line */
}

.container:active {
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
  transform: translateY(2px);
}
